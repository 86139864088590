import { Component, Input } from '@angular/core';
import { CustomParamComponent } from '../customParam.component';
import { CustomParamsBillingInvoiceModel } from './customParamsBillingInvoiceModel';
import { CommonMethods } from '../commonMethods';
import { AppHttpService } from '../../apphttp.Service';
import { Globals } from '../../globals.Service';



@Component({
  templateUrl: './customParamsBillingInvoice.component.html'
})
export class CustomParamsBillingInvoiceComponent implements CustomParamComponent {
  @Input() data: any;
  method = new CommonMethods();
  model = new CustomParamsBillingInvoiceModel();
  invoiceDateList = [];

  constructor(private appHttpService: AppHttpService, public globals: Globals) {

  }

  ngOnInit() {
    this.model = new CustomParamsBillingInvoiceModel();
    this.model.InvoiceDate = "";
    this.invoiceDateSelect();
  }

  invoiceDateSelect() {
    this.appHttpService.get("InvoiceDateDropDownList", "").then(res => {
      this.invoiceDateList = res;
      if (this.invoiceDateList.length > 0) {
        this.model.InvoiceDateNumber = this.invoiceDateList[0].value;
      }
      this.addtoParameterList();
    });
  }

  addtoParameterList() {
    if (this.model.InvoiceDateNumber != undefined && this.invoiceDateList.length > 0) {
      for (var i = 0; i < this.invoiceDateList.length; i++) {
          if (this.invoiceDateList[i].value == this.model.InvoiceDateNumber) {
            this.model.InvoiceDate = this.invoiceDateList[i].label;
            break;
          }
      }
    }
    this.method.customPopulateFieldForCustomComponenents(this.model, document.getElementById("customParameterList"));
  }
}
